var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap doc_req_pop", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SETT010T010.019"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("msg.SETT010T010.020")) + " "),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.$t("msg.SETT010T010.021")) + " "),
          ]),
          _c("p", { staticClass: "mt10" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.SETT010T010.022")) + " "),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.$t("msg.SETT010T010.023")) + " "),
          ]),
          _c("table", { staticClass: "tbl_col mt10" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.024"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.025"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.026"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.027"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.028"))),
                ]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.detail.vslNm) + " / " + _vm._s(_vm.detail.voyNo)
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.exrDt))]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v(_vm._s(_vm.getCurrencyFormat(_vm.exrVn))),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.invDt))]),
                _c("td", { staticClass: "text_right" }, [
                  _vm._v(_vm._s(_vm.getCurrencyFormat(_vm.invVn))),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onInvoiceCancel.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.029")))]
          ),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.030")))]
          ),
        ]),
      ]),
      _c("invoice-publish-pop", {
        ref: "invoicePub",
        on: { "childs-event": _vm.parentsMethod },
      }),
      _c(
        "div",
        { attrs: { id: "layer-invoice-parent" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.custCompInvoiceParent
                ? _c(_vm.custCompInvoiceParent, {
                    tag: "component",
                    attrs: {
                      "is-show-pop": _vm.isInvoiceShowPopParent,
                      "parent-info": _vm.childParam,
                    },
                    on: { close: _vm.closeInvoceParent },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "29%" } }),
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "29%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }