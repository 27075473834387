<template>
  <div class="popup_wrap doc_req_pop" style="width:800px">
    <!-- popup_wrap : style="width:700px height:550px" 참고용, 개발시 제거 -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.SETT010T010.019') }}</h1>
      <div class="content_box">
        <!--content_box  -->
        <p>
          {{ $t('msg.SETT010T010.020') }} <br />
          {{ $t('msg.SETT010T010.021') }}
        </p>

        <p class="mt10">
          {{ $t('msg.SETT010T010.022') }} <br />
          {{ $t('msg.SETT010T010.023') }}
        </p>

        <table class="tbl_col mt10">
          <colgroup>
            <col style="width:20%">
            <col style="width:14%">
            <col style="width:29%">
            <col style="width:14%">
            <col style="width:29%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">{{ $t('msg.SETT010T010.024') }}</th>
              <th scope="col">{{ $t('msg.SETT010T010.025') }}</th>
              <th scope="col">{{ $t('msg.SETT010T010.026') }}</th>
              <th scope="col">{{ $t('msg.SETT010T010.027') }}</th>
              <th scope="col">{{ $t('msg.SETT010T010.028') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ detail.vslNm }} / {{ detail.voyNo }}</td>
              <td>{{ exrDt }}</td>
              <td class="text_right">{{ getCurrencyFormat(exrVn) }}</td>
              <td>{{ invDt }}</td>
              <td class="text_right">{{ getCurrencyFormat(invVn) }}</td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->

      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="onInvoiceCancel">{{ $t('msg.SETT010T010.029') }}</a>
        <a class="button blue lg" @click="$emit('close')">{{ $t('msg.SETT010T010.030') }}</a>
      </div>
    </div><!-- popup_cont -->
    <invoice-publish-pop ref="invoicePub" @childs-event="parentsMethod" />

    <div id="layer-invoice-parent">
      <win-layer-pop>
        <component
          v-if="custCompInvoiceParent"
          :is="custCompInvoiceParent"
          :is-show-pop="isInvoiceShowPopParent"
          :parent-info="childParam"
          @close="closeInvoceParent"
        />
      </win-layer-pop>
    </div>
  </div><!-- popup_wrap // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import CalcIssue from '@/api/rest/settle/calcIssue'
import moment from 'moment'
import InvoicePublishPop from '@/pages/settle/popup/InvoicePublishPop' // Invoice발행 및 출력 공통
import BlInvoiceFreightPop from '@/pages/pdf/popup/BlInvoiceFreightPop'
import BlProformaInvoiceIdPop from '@/pages/pdf/popup/BlProformaInvoiceIdPop'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import WinLayerPopWrap from '@/pages/trans/popup/WinLayerPopWrap'

export default {
  name: 'CalcIssueRatePop',
  components: {
    InvoicePublishPop,
    WinLayerPop,
    BlInvoiceFreightPop,
    BlProformaInvoiceIdPop,
    WinLayerPopWrap
  },
  props: {
    detail: {
      type: Object,
        default: function () {
        return {
          voyNo: '',
          vslNm: '',
          eiCatCd: '',
          blNo: ''
        }
      }
    },
    isShowPop: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
      return {
        blInfo: null,
        state1: '',
        link: '',
        idx: 0,
        invDt: '',
        invVn: '',
        exrDt: '',
        exrVn: '',

        popFlag: true,
        custCompInvoiceParent: null,
        isInvoiceShowPopParent: null,
        childParam: null
      }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    detail (n, o) {
      this.init()
    },
    isShowPop () {
      //
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init () {
      this.blInfo = this.detail
      this.showData()
    },
    showData () {
      const param = {
        eiCatCd: this.blInfo.eiCatCd,
        blNo: this.blInfo.blNo
      }
      CalcIssue.getExrtInfo(param)
      .then(response => {
        console.log(response)
        const resp = response.data
        this.invDt = this.$_.trim(resp.invDt).length === 8 ? moment(this.$_.trim(resp.invDt), 'YYYYMMDD').format('YYYY.MM.DD') : ''
        this.invVn = this.blInfo.exRate
        this.exrDt = this.$_.trim(resp.exrDt).length === 8 ? moment(this.$_.trim(resp.exrDt), 'YYYYMMDD').format('YYYY.MM.DD') : ''
        this.exrVn = resp.exrVn
      })
      .catch(err => {
        console.log(err)
      })
    },
    async getInvoiceInfo (data) {
      let invoiceData = []
      const v = this.blInfo
      if (this.$_.trim(v.invIssNo) !== '') {
        if (v.frtCd === 'Freight') {
          const tt = {
            invIssNo: data.invIssNo,
            blNo: data.blNo,
            invRno: data.invRno,
            index: 0
          }
          console.log('--------tt-------')
          console.log(tt)
          console.log('--------tt-------')
          invoiceData.push(tt)
          this.popFlag = false
          this.$refs.invoicePub.openInvoicePop(invoiceData, v.eiCatCd, 'popCalcRate')
        }
      }
    },
    async onInvoiceCancel () {
      // 인보이스 취소
      const v = this.blInfo
      if (this.$_.trim(v.invIssNo) !== '') {
        if (v.frtCd === 'Freight') {
          const param = {
            invIssNo: v.invIssNo
          }
          await CalcIssue.invoiceCancel(param)
            .then(async response => {
              if (response.data) {
                const result = response.data
                if (this.$_.has(result, 'rtnMsg')) {
                  if (result.rtnCode === 'SUCC') {
                    let req = {
                      asLangCd: this.auth.serviceLang,
                      inBound: this.detail.eiCatCd,
                      blList: [{
                        blNo: v.blNo,
                        invRno: v.invRno,
                        index: v.reqCnt,
                        mainYn: 'N'
                      }]
                    }
                    this.invoicePublish(req)
                  } else {
                    this.openAlert(result.rtnMsg)
                  }
                }
              }
            })
        }
      }
    },
    invoicePublish (req) {
       CalcIssue.invoicePublish(req)
        .then(async response => {
          if (response.data) {
              await this.getInvoiceInfo(response.data[0])
          }
        }).catch(err => {
          console.log(err)
        })
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },

    getCurrencyFormat (number, cstCd = 'USD') {
      if (cstCd === 'KRW') {
        return new Intl.NumberFormat('ko-KR', { currency: 'KRW' }).format(number)
      } else if (cstCd === 'VND') {
        return new Intl.NumberFormat('ko-KR', { currency: 'VND' }).format(number)
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          currency: 'USD'
        }).format(number)
      }
    },
    parentsMethod (componentName, params) {
      console.log('parentsMethod ::: ', componentName)
      console.log('parentsMethod params::: ', params)

      this.childParam = params
      this.custCompInvoiceParent = componentName
      this.isInvoiceShowPopParent = true

      $('#layer-invoice-parent > .popup_dim').fadeIn()
      $('body, html').css('overflow', 'hidden')
    },
    closeInvoceParent () {
      this.childParam = {}
      this.custCompInvoiceParent = null
      this.isInvoiceShowPopParent = false
      $('#layer-invoice-parent  > .popup_dim').fadeOut()
      $('body, html').removeAttr('style')
    }
  }
}

</script>

<style scoped>
a.no-uline:hover{
  text-decoration:underline
}
.doc_req_pop > .layer_close {z-index: 10;}

</style>
